import React from 'react';
import ExpoSnack from 'react-expo-snack';

import SEO from '../components/seo';

const Buttonmash = () => (
  <>
    <SEO title="buttonmaash" />
    <h1>buttonmaash</h1>
    <p>the page about buttonmaash the game where u maash button</p>
    <ExpoSnack id="@perezvon/buttonmaash" />
  </>
);

export default Buttonmash;
